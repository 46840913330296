import React from 'react';
import { Box, Spinner, Center } from '@chakra-ui/react';

import MainLayout from '@components/Layout';
import QRCard from '@components/Common/QRCard/V2';
import { GuestProvider } from '@/context/guest';

import useGuestInfo from '@/usecase/use-guest-info';
import useRedirectToHomepage from '@/hooks/useRedirectToHomepage';

import { ENABLE_QR_INVITATION } from '@/constants/feature-flags';

export default function ETicket() {
  const { isLoading } = useGuestInfo();

  // redirect to homepage if not eligible
  useRedirectToHomepage(!ENABLE_QR_INVITATION);

  /**
   * IF QR Feature not enable,
   * then return nothing
   */
  if (!ENABLE_QR_INVITATION) return null;

  return (
    <GuestProvider>
      <MainLayout>
        <Box minHeight="100vh">
          <div className="homepage">
            {!isLoading ? (
              <Box padding="8px">
                <QRCard isDirectLink={false} />
              </Box>
            ) : (
              <Center height="100vh">
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="mainColorText"
                  size="xl"
                  margin="auto"
                />
              </Center>
            )}
          </div>
        </Box>
      </MainLayout>
    </GuestProvider>
  );
}
